import { AxiosInstance } from 'axios'
import {
  // ShippingMethod,
  ShippingMethodsResponse
} from '~/types/shipping'

export default function (instance: AxiosInstance) {
  const base = 'api/shipping/default'

  // const mapCountry = (dataCountry: any): TranssmartDeliveryMapCountry => ({
  //   countryCodes: dataCountry.CountryCodes,
  //   options: dataCountry.Options.map((dataOption: any) => ({
  //     optionId: dataOption.OptionId,
  //     deliveryType: dataOption.DeliveryType
  //   }))
  // })

  return {
    async checkout(): Promise<ShippingMethodsResponse | null> {
      const response = await instance.get(`/${base}/methods`)
      // if (!response.data.Success) return null


      return {
        countries: response.data?.Countries
        // countryCode: response.data.CountryCode,
        // id: response.data.ShippingCost,
        // methods: response.data.ShippingDeliveryType
      }
    }
  }
}
