import { AxiosInstance, AxiosResponse } from 'axios'
import { Dictionary } from 'ramda'
import { Context } from '@nuxt/types'
import {
  convertBrandSuggestion,
  convertSuggestion,
} from '../deserializers/sugesstion.meli'
import {
  FilterFields,
  ProductListOptions,
  ProductSearchRequest,
  ProductSearchResult,
  ProductSortOption,
} from '@/types/product'
import {
  convertFacets,
  convertProducts,
  convertSortOption,
} from '~/lib/api/deserializers/product.meili'
import { Suggestions } from '~/types/sugesstion'

const SUGGESTION_KEYS = ['products', 'brands', 'categories'] as const

export default function (instance: AxiosInstance, { app }: Context) {
  const base = 'api/meilisearch/v1'
  const currentCulture = app.i18n.localeProperties?.iso ?? 'nl-NL'

  return {
    async search(
      payload: ProductSearchRequest
    ): Promise<ProductSearchResult | null> {
      // Prepare parameters
      const request: Dictionary<any> = {
        limit: payload.limit,
        offset: payload.offset,
      }

      // build facet filters
      const baseFacetFilters: (string | string[])[] = [
        'IsVariantProductString=False',
        'ProductType!=ProductVoucher',
      ]

      if (payload.webNodeId) {
        baseFacetFilters.push(`WebNodeIds=${payload.webNodeId}`)
      }

      const facetFilters: (string | string[])[] = []

      if (payload.priceRange) {
        facetFilters.push(
          `${FilterFields.Prices}>=${payload.priceRange.min}`,
          `${FilterFields.Prices}<=${payload.priceRange.max}`
        )
      }

      if (payload.filters?.size) {
        const filters: string[][] = []

        ;[...payload.filters.entries()].forEach(([field, values]) => {
          const filterGroups = values.map((value) => `${field}='${value}'`)
          filters.push(filterGroups)
        })

        facetFilters.push(...filters)
      }

      if (payload.sortBy) {
        request.sortBy = JSON.stringify([payload.sortBy])
      }

      if (payload.query) {
        request.searchTerm = payload.query
      }

      // Call API
      const response: AxiosResponse = await instance.get(
        `/${base}/default/search`,
        {
          params: {
            baseFacetFilters: JSON.stringify(baseFacetFilters),
            facetFilters: JSON.stringify(facetFilters),
            ...request,
            culture: currentCulture,
          },
        }
      )

      if (!response.data.Success) return null

      const facets = convertFacets(response.data.FacetsDistribution)

      const RangeFacetsDistribution =
        response.data.RangeFacetsDistribution ?? []
      const minPrice =
        RangeFacetsDistribution.find(
          (item: any) => item.Name === FilterFields.MinPrice
        )?.MinValue ?? 0
      const maxPrice =
        RangeFacetsDistribution.find(
          (item: any) => item.Name === FilterFields.MaxPrice
        )?.MaxValue ?? 500

      return {
        products: convertProducts(response.data.Hits),
        facets,
        total: response.data.NbHits,
        priceRange: {
          incl: {
            min: minPrice,
            max: maxPrice,
          },
        },
      }
    },

    async getListOptions(): Promise<ProductListOptions | null> {
      const response: AxiosResponse = await instance.get(`/${base}/config`)
      if (!response.data.Success) return null

      const data = response.data.Data
      const sortOptions =
        data.SortableAttributes?.reduce(
          (prev: ProductSortOption[], current: any) => {
            if (current.Name !== FilterFields.MaxPrice) {
              prev.push(convertSortOption(current))
            }
            return prev
          },
          []
        ) ?? []

      return {
        facetLabels: data.AttributesForFacetting,
        sortOptions,
        // mock data
        limitOptions: [
          {
            label: '12',
            value: 12,
          },
          {
            label: '24',
            value: 24,
          },
          {
            label: '48',
            value: 48,
          },
          {
            label: '60',
            value: 60,
          },
        ],
      }
    },

    async suggest(payload: {
      term: string
      limit?: number
    }): Promise<Suggestions | null> {
      const { data } = await instance.get(`/${base}/multi-search`, {
        params: {
          limit: payload.limit ?? 5,
          searchTerm: payload.term,
          culture: currentCulture,
        },
      })

      if (!data) return null

      const result: Partial<
        Record<typeof SUGGESTION_KEYS[number], { name: string; hits: any[] }>
      > = {}

      data.forEach((item: any) => {
        const index = item?.Index
        if (!index) return

        const indexName = index.IndexName
        if (indexName) {
          SUGGESTION_KEYS.some((str) => {
            if (indexName.includes(str)) {
              result[str] = {
                name: index.DisplayName,
                hits: item.Hits ?? [],
              }
              return true
            }
            return false
          })
        }
      })

      return {
        brands: result.brands?.hits?.length
          ? {
              name: result.brands.name ?? '',
              suggestions: result.brands.hits.map((item) =>
                convertBrandSuggestion(item)
              ),
            }
          : undefined,
        categories: result.categories?.hits?.length
          ? {
              name: result.categories.name ?? '',
              suggestions: result.categories.hits.map((item) =>
                convertSuggestion(item)
              ),
            }
          : undefined,
        products: result.products?.hits?.length
          ? {
              name: result.products?.name ?? '',
              suggestions: convertProducts(result.products.hits),
            }
          : undefined,
        // aheadWords: later
      }
    },
  }
}
