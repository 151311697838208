import { AxiosInstance, AxiosResponse } from 'axios'
import { convertSocialLink } from '../deserializers/common'
import { Store, StoreParams, OrderBy } from '@/types/store'
import { convertStore, convertStores } from '~/lib/api/deserializers/store'
import { Coordinate } from '~/types/geo'
import { SocialLink } from '~/types/common'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/stores'

  return {
    async getStores(
      payload?: StoreParams,
      coordinate?: Coordinate
    ): Promise<Store[]> {
      const defaultParams: StoreParams = {
        storeTags: '["Webshop"]',
      }
      const params = Object.assign(defaultParams, payload)

      if (coordinate !== undefined) {
        params.distanceFromLatitude = coordinate.lat
        params.distanceFromLongitude = coordinate.lng
      }
      try {
        const response: AxiosResponse = await instance.get(`/${base}`, {
          params,
        })
        if (!response.data.Success) return []
        const orderBy = coordinate ? OrderBy.Distance : OrderBy.Alphabetic
        return convertStores({
          stores: response.data.Stores,
          storeUrls: response.data.StoreUrls,
          extraFields: response.data.ExtraFields,
          orderBy,
          noDistance: !coordinate,
        })
      } catch {
        return []
      }
    },

    async getByUrl(url: string): Promise<Store | null> {
      const response: AxiosResponse = await instance.get(`/${base}/url/${url}`)
      if (!response.data.Success) return null
      return convertStore(response.data.Store, response.data.ExtraFields)
    },

    async getById(id: number): Promise<Store | null> {
      const response: AxiosResponse = await instance.get(`/${base}/${id}`)
      if (!response.data.Success) return null
      return convertStore(response.data.Store, response.data.ExtraFields)
    },

    async getSocialLinks(storeId: number): Promise<SocialLink[]> {
      const { data } = await instance.get(`/${base}/${storeId}/social`)
      if (data?.Success === false) return []

      return data.SocialLinks?.map((link: any) => convertSocialLink(link)) ?? []
    },
  }
}
