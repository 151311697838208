import { AxiosInstance, AxiosResponse } from 'axios'
import { Preference, Vat } from '~/types/preference'
import { convertPreference, convertVat } from '~/lib/api/deserializers/preference'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/preference'

  return {
    async getPreference(): Promise<Preference | null> {
      const response: AxiosResponse = await instance.get(`/${base}`)
      if (!response.data.Success)
        return null

      return convertPreference(response.data.Data)
    },

    async setStore(storeId: number | string): Promise<Preference | null> {
      const response: AxiosResponse = await instance.post(`/${base}/store`, {
        storeId,
      })
      if (!response.data.Success)
        return null

      return convertPreference(response.data.Data)
    },

    async getVatSetting(): Promise<Vat | null> {
      const response: AxiosResponse = await instance.get(`/${base}`)
      if (!response.data.Success)
        return null

      return convertVat(response.data.Data)
    },

    async setVat(includeVat: boolean): Promise<Vat | null> {
      const response: AxiosResponse = await instance.post(`/${base}/vat`, {
        includeVat,
      })

      if (!response.data.Success)
        return null

      return convertVat(response.data.Data)
    },
  }
}
