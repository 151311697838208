import { AxiosInstance, AxiosResponse } from 'axios'
import { convertWishlist } from '~/lib/api/deserializers/wishlist'
import { AddWishlistParams } from '~/types/wishlist'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/customer/wishlists'

  return {
    async getWishlists(): Promise<any> {
      const response: AxiosResponse = await instance.get(`/${base}`)
      if (!response.data) return null
      return convertWishlist(response.data?.Wishlists)
    },

    async addWishlist(name: string): Promise<any> {
      const response: AxiosResponse = await instance.post(`/${base}`, {
        listName: name,
      })
      if (!response.data) return null
      return response.data?.Success
    },

    async addProductToWishlist(
      wishlistParams: AddWishlistParams
    ): Promise<any> {
      const response: AxiosResponse = await instance.post(
        `/${base}/${wishlistParams.wishlist}/products`,
        {
          productId: wishlistParams.productId,
          quantity: wishlistParams.quantity,
        }
      )
      if (!response.data) return null
      return response.data?.Success
    },

    async deleteProductFromWishlist(
      wishlistParams: AddWishlistParams
    ): Promise<any> {
      const response: AxiosResponse = await instance.delete(
        `/${base}/${wishlistParams.wishlist}/products/${wishlistParams.productId}`
      )
      if (!response.data) return null
      return response.data?.Success
    },

    async checkProductInWishlist(productId: number): Promise<any> {
      const response: AxiosResponse = await instance.get(
        `/api/aspos/customer/wishlists-check/${productId}`
      )
      if (!response.data?.Success) return null
      return convertWishlist(response.data?.Wishlists)
    },

    async addProductToWishlistFromCart(name: string): Promise<any> {
      const response: AxiosResponse = await instance.post(`/${base}/cart`, {
        listName: name
      })
      if (!response.data) return null
      return response.data?.Success
    },
  }
}
