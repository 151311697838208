import { AxiosInstance } from 'axios'
import { Transaction, TransactionsRequest } from '~/types/transactions'
import {
  convertTransaction,
  convertTransactions,
} from '~/lib/api/deserializers/transactions'
export default function (instance: AxiosInstance) {
  const base = 'api/aspos/customer/transactions'

  return {
    async getTransactions(
      payload: TransactionsRequest
    ): Promise<Transaction[]> {
      const defaultParams: TransactionsRequest = {
        limit: 4,
        offset: 0,
        includeRecords: true,
        checkProductsInMainTree: true,
      }
      const params = Object.assign(defaultParams, payload)
      const { data } = await instance.get(`/${base}`, {
        params,
      })
      if (!data.Success) return []

      return convertTransactions(
        data.Transactions,
        data.ProductUrls,
        data.ProductsInMainTree
      )
    },

    async getTransactionById(id: number): Promise<Transaction | null> {
      const { data } = await instance.get(`/${base}/${id}`)
      if (!data.Success) return null

      return convertTransaction(data.Transaction)
    },
  }
}
