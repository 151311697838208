import { AxiosInstance, AxiosResponse } from 'axios'
import {
  convertVoucher,
  convertVouchers,
  convertCoupon
} from '~/lib/api/deserializers/promotion'
import { VoucherResult, VoucherByCodeResult } from '~/types/promotion'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/customer/vouchers'

  return {
    async getVouchers(): Promise<VoucherResult | null> {
      const params = {
        includeProducts: true,
      }
      const response: AxiosResponse = await instance.get(`/${base}`, { params })

      if (!response.data.Success) return null

      const vouchers = [
        ...(response.data.Vouchers ?? []),
        ...(response.data.VouchersWithNoProducts ?? []),
      ]

      const paymentVouchers = vouchers.filter(
        (voucher: any) => voucher.Type?.Kind === 'Betaalvoucher'
      )

      const productVouchers = vouchers.filter(
        (voucher: any) => voucher.Type?.Kind === 'Productvoucher'
      )

      return {
        paymentVouchers: convertVouchers(paymentVouchers),
        productVouchers: convertVouchers(productVouchers),
      }
    },

    async getVoucherByCode(code: string): Promise<VoucherByCodeResult | null> {
      const response = await instance.get(`/${base}/code/${code}`)
      if (!response.data.Success) return null

      if (response.data.Voucher) {
        return {
          voucher: convertVoucher(response.data.Voucher)
        }
      } else {
        return {
          coupon: convertCoupon(response.data.Coupon)
        }
      }
    }
  }
}
